<template>
  <div v-if="order">

    <CRow class="ml-0">
      <h1 class="main-header" v-translate>
        Order detail
      </h1>
      <div class="ml-4">
        <DatePickerTimeZone
            :timeZoneValue="timeZonePickerValue"
            @input="onDatePickerInput"
            :extraTimezones="customTimezoneList"
            :showDatePicker="false"
            :showTimeZonePicker="showTimeZonePicker"
            :showTimeZoneText="!showTimeZonePicker"
        />
      </div>
    </CRow>

    <CCard>
      <CCardHeader>
        <h2 class="mb-0" v-if="order.total_amount && hasPerm('orders.fields.total_amount')">
          {{ order.total_amount|currency(order.currency) }}
        </h2>
        <h2 class="mb-0" v-else>
          Info
        </h2>

        <div class="card-header-actions">
          <PButton :loading="loadingSetOrderAsPaid" @click="setOrderAsPaid()" class="btn btn-primary btn-sm"
                   v-if="(hasPerm('orders.change_status'))  && (order.payment_method !== null && order.payment_method.name === Constants.ORDER_PAYMENT_METHOD_BANK_TRANSFER) && (order.status !== orderStatus.PAID)"
                   v-translate>
            Set as paid
          </PButton>
          <CButton color="primary" class="ml-2" v-translate v-if="hasPerm('orders.refund.create')">Refund</CButton>
        </div>
      </CCardHeader>

      <CCardBody class="p-0">
        <div class="table-responsive">
          <table class="table table-striped table-compact">
            <thead>
            <tr>
              <th scope="col">
                <translate>Order number</translate>
              </th>
              <th scope="col">
                <translate>Date</translate>
              </th>
              <th scope="col" v-if="hasPerm('orders.fields.order_user') && isInEventOrders">
                <translate>Customer</translate>
              </th>
              <th scope="col">
                <translate>Payment method</translate>
              </th>
              <th scope="col" v-if="hasPerm('orders.fields.status')">
                <translate>Order status</translate>
              </th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>{{ order.order_number }}</td>
              <td>{{ order.created_at|formatDateTimeTZ(timezone, 'LLL') }}</td>
              <td v-if="hasPerm('orders.fields.order_user') && isInEventOrders">{{ order.user.email }}</td>
              <td>{{ order.payment_method !== null ? order.payment_method.label : '' }}</td>
              <td>
                <CBadge class="badge-outline" shape="pill" color="light" v-if="hasPerm('orders.fields.status')">
                  <div class="d-inline-flex align-items-center py-1">
                    <CIcon name="cipDot" :class="getStatusColor(order.status)" size="custom-size" :height="6"/>
                    <span class="ml-1">{{ order.status_display }}</span>
                  </div>
                </CBadge>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </CCardBody>
    </CCard>

    <CCard>
      <CCardHeader>
        <h5 class="my-2" v-translate>Payment details</h5>
      </CCardHeader>

      <CCardBody class="px-0">
        <CContainer fluid>
          <dl class="row mb-0 list-striped">
            <dt class="col-sm-5 col-xl-4">
              <translate>Currency</translate>
            </dt>
            <dd class="col-sm-7 col-xl-8">
              {{ order.currency }}
            </dd>
            <template v-if="order.price_digital_items_total && hasPerm('orders.fields.digital_item')">
              <dt class="col-sm-5  col-xl-4">
                <translate>Amount</translate>
              </dt>
              <dd class="col-sm-7  col-xl-8">
                {{ order.price_digital_items_total|currency(order.currency) }}
              </dd>
            </template>
            <template v-if="order.total_amount_tax && hasPerm('orders.fields.total_amount')">
              <dt class="col-sm-5  col-xl-4">
                <translate>Total amount tax</translate>
              </dt>
              <dd class="col-sm-7  col-xl-8">
                {{ order.total_amount_tax|currency(order.currency) }}
              </dd>
            </template>
            <template v-if="order.platform_fee_total && hasPerm('orders.fields.platform_fee_total')">
              <dt class="col-sm-5 col-xl-4">
                <translate>Platform fee</translate>
              </dt>
              <dd class="col-sm-7 col-xl-8">
                {{ order.platform_fee_total|currency(order.currency) }}
                <div v-if="order.platform_fee_additional">
                  <span>(Pica: {{ order.platform_fee_pica|currency(order.currency) }}</span>
                  <span><translate>Additional</translate>: {{ order.platform_fee_additional|currency(order.currency) }})</span>
                </div>
              </dd>
            </template>
            <template v-if="order.amount_net_photo_service && hasPerm('orders.fields.net_photo_service')">
              <dt class="col-sm-5  col-xl-4">
                <translate>Net amount</translate>
              </dt>
              <dd class="col-sm-7  col-xl-8">
                {{ order.amount_net_photo_service|currency(order.currency) }}
              </dd>
            </template>
            <template v-if="order.price_print_items_total && hasPerm('orders.fields.print_items')">
              <dt class="col-sm-5  col-xl-4">
                <translate>Print items</translate>
              </dt>
              <dd class="col-sm-7  col-xl-8">
                {{ order.price_print_items_total|currency(order.currency) }}
              </dd>
            </template>
            <template v-if="order.price_shipping && hasPerm('orders.fields.shipping')">
              <dt class="col-sm-5  col-xl-4">
                <translate>Shipping</translate>
              </dt>
              <dd class="col-sm-7  col-xl-8">
                {{ order.price_shipping|currency(order.currency) }}
              </dd>
            </template>
            <template v-if="order.payment_transaction_fee && hasPerm('orders.fields.transaction_fee')">
              <dt class="col-sm-5 col-xl-4">
                <translate>Card fee</translate>
              </dt>
              <dd class="col-sm-7 col-xl-8">
                {{ order.payment_transaction_fee|currency(order.currency) }}
              </dd>
            </template>
            <template v-if="order.amount_tax && hasPerm('orders.fields.amount_tax')">
              <dt class="col-sm-5  col-xl-4">
                <translate>VAT</translate>
              </dt>
              <dd class="col-sm-7 col-xl-8">
                {{ order.amount_tax|currency(order.currency) }}
              </dd>
            </template>
            <template v-if="order.billing_country && hasPerm('orders.fields.billing_address')">
              <dt class="col-sm-5  col-xl-4">
                <translate>Billing address</translate>
              </dt>
              <dd class="col-sm-7  col-xl-8">
                {{ order.billing_country.name }} - {{ order.billing_postal_code }}
              </dd>
            </template>
            <template v-if="hasPerm('orders.fields.platform')">
              <dt class="col-sm-5  col-xl-4">
                <translate>Platform</translate>
              </dt>
              <dd class="col-sm-7  col-xl-8">
                {{ order.payment_platform_display }}
              </dd>
            </template>
            <template v-if="hasPerm('orders.fields.transaction_id')">
              <dt class="col-sm-5  col-xl-4">
                <translate>Payment transaction ID</translate>
              </dt>
              <dd class="col-sm-7  col-xl-8">
                {{ order.payment_transaction_id }}
              </dd>
            </template>
            <template v-if="hasPerm('orders.fields.debug')">
              <dt class="col-sm-5  col-xl-4">
                <translate> Payment transaction result</translate>
              </dt>
              <dd class="col-sm-7  col-xl-8">
                <div style="max-height: 500px; overflow-y: auto; font-size: small;">
                  <pre>{{ order.payment_transaction_result }}</pre>
                </div>
              </dd>
            </template>
          </dl>
        </CContainer>
      </CCardBody>
    </CCard>

    <CCard>
      <CCardHeader>
        <h6 class="my-2" v-translate>Digital photos</h6>
      </CCardHeader>

      <CCardBody class="">
        <CDataTable v-for="(item, index) in order.order_items" :key="index"
                    :fields="fields"
                    :items="[item]"
                    class="custom-table mb-4">

          <template #pica_service="{item}">
            <td>
              <div v-if=" item.pica_service.event_name">{{ item.pica_service.event_name }}</div>
              <div>{{ item.pica_service.event_start_at|formatDateTimeTZ(timezone, "LL") }}</div>
            </td>
          </template>
          <template #start_of_stay="{item}">
            <td v-if="item.start_of_stay">
              {{ item.start_of_stay|formatDateTimeTZ(timezone, "LL") }}
            </td>
            <td v-else></td>
          </template>
          <template #end_of_stay="{item}">
            <td v-if="item.end_of_stay">
              {{ item.end_of_stay|formatDateTimeTZ(timezone, "LL") }}
            </td>
            <td v-else></td>
          </template>
          <template #price="{item}">
            <td>
              {{ item.price|currency(order.currency) }}
            </td>
          </template>

          <template #amount_tax="{item}">
            <td>
              {{ item.amount_tax|currency(order.currency) }}
            </td>
          </template>

          <template #details="{ item }">
            <CCollapse :show="Boolean(item._toggled)" :duration="collapseDuration">
              <CCardBody>
                <CRow>
                  <CCol sm="6" md="4" lg="4" xl="3" v-for="photo in orderItemsPhotos[item.id]" :key="photo.id">
                    <div class="mb-3">
                      <figure class="figure position-relative w-100 order__figure rounded">
                        <v-lazy-image :src="photo.image_url"
                                      class="figure-img img-fluid  w-100"
                                      :src-placeholder="photo.original_width > photo.original_height ? placeholderLandscape : placeholderPortrait"/>

                        <CDropdown
                            v-if="item.order_type === 'photolist'"
                            placement="bottom-end"
                            class="no-arrow order__figure-actions"
                        >
                          <template #toggler-content>
                            <CIcon name="cip-more"/>
                          </template>
                          <CDropdownItem
                              v-if="hasPerm('pica_ecommerce.change_order')"
                              @click="loadModalReplacePhoto(item.id, photo, orderItemsPhotos[item.id], item.picacode, item.pica_service.id)">
                            <translate>Replace photo</translate>
                          </CDropdownItem>
                        </CDropdown>
                      </figure>
                      <figcaption class="figure-caption">ID: {{ photo.id }}</figcaption>
                    </div>

                  </CCol>
                </CRow>
              </CCardBody>
            </CCollapse>
          </template>

          <template #actions-header>
            <div class="text-right">
              <ul class="list-inline text-end m-0 text-nowrap">

                <li class="list-inline-item"
                    v-if="item.order_type.includes(Constants.ORDER_TYPE_PHOTOPACKAGE) && hasPerm('pica_ecommerce.change_order')">
                  <CButton variant="outline" color="primary"
                           @click="loadModalReplaceAlbum(item.id, index, item.picacode, item.pica_service.id)">
                    <translate>Replace album</translate>
                  </CButton>
                </li>

                <li class="list-inline-item" v-if="hasPerm('orders.media.list')">
                  <CButton :class="['btn-grid-toggle', {'on': item._toggled}]" @click="toggleDetails(item)"/>
                </li>
              </ul>

            </div>
          </template>
          <template #actions>
          </template>
        </CDataTable>

        <CModal size="xl" :title="$gettext('Replace photo')" :show.sync="showModalReplacePhoto">
          <OrderPhotoGalleryPhotoReplacement :selectablePhotos="selectablePhotosForReplacement"
                                             :selectedPhotoId="selectedPhotoId"
                                             :currentPhoto="currentPhoto"
                                             :loading="loadingPhotosForReplacement"
                                             @select-photo-for-replacement="selectPhotoForReplacement"
                                             ref="OrderPhotoGalleryPhotoReplacement" v-if="currentPhoto"/>

          <template #footer>
            <CButton @click="showModalReplacePhoto = false" color="secondary">
              <template>
                <translate>Cancel</translate>
              </template>
            </CButton>

            <PButton :disabled="!currentPhoto || currentPhoto.id === selectedPhotoId"
                     :loading="loadingPhotoReplacement"
                     class="btn btn-primary btn-sm" @click="replacePhoto(currentPhoto, selectedPhotoId)">
              <translate>Proceed</translate>
            </PButton>
          </template>
        </CModal>

        <CModal size="xl" :title="$gettext('Replace album')" :show.sync="showModalReplaceAlbum">
          <OrderPhotoGalleryAlbumReplacement
              :loading="loadingAlbumForReplacement"
              :oldPicacode="currentPicacode"
              :picaServiceId="picaServiceIdForAlbumReplacement"
              ref="OrderPhotoGalleryAlbumReplacement"/>

          <template #footer>
            <CButton @click="showModalReplaceAlbum = false" color="secondary">
              <template>
                <translate>Cancel</translate>
              </template>
            </CButton>

            <PButton :loading="loadingAlbumReplacement"
                     class="btn btn-primary"
                     @click="replaceAlbum"
                     :disabled="!$refs.OrderPhotoGalleryAlbumReplacement || $refs.OrderPhotoGalleryAlbumReplacement.picacodePhotos.length === 0">
              <translate>Proceed</translate>
            </PButton>
          </template>
        </CModal>
      </CCardBody>
    </CCard>

  </div>
</template>

<script>
import OrderService from "@/services/order.service"
import MediaService from "@/services/media.service"
import OrderPhotoGalleryPhotoReplacement from "../../components/OrderPhotoGalleryPhotoReplacement"
import OrderPhotoGalleryAlbumReplacement from "../../components/OrderPhotoGalleryAlbumReplacement"
import DatePickerTimeZone from "@/components/DatePickerTimeZone.vue";
import PButton from "../../components/PButton"
import Constants, {orderStatus} from "@/constants"
import {mapActions, mapGetters} from "vuex";

export default {
  name: "OrderDetail",

  components: {
    OrderPhotoGalleryPhotoReplacement,
    OrderPhotoGalleryAlbumReplacement,
    PButton,
    DatePickerTimeZone
  },

  data() {
    return {
      Constants,
      order: null,
      orderItemsFields: [
        {
          key: "picacode",
          label: "Pica Code",
          _style: 'width:120px;'
        },
        {
          key: "order_type_display",
          label: this.$gettext("Order type"),
          _style: 'width:200px;'
        },
        {
          key: "pica_service",
          label: "Pica Service",

        },
        {
          key: "start_of_stay",
          label: this.$gettext("Start of stay"),
        },
        {
          key: "end_of_stay",
          label: this.$gettext("End of stay"),
        },
        {
          key: "price",
          label: this.$gettext("Price"),
          _style: 'width:150px;'
        },
        {
          key: "amount_tax",
          label: this.$gettext("VAT"),
          _style: 'width:150px;'
        },
        {
          key: "actions",
          label: "",
          //  _style: 'width:250px;'
        }
      ],
      orderItemsPhotos: {},
      collapseDuration: 0,
      showModalReplacePhoto: false,
      showModalReplaceAlbum: false,
      selectablePhotosForReplacement: [],
      selectedPhotoId: null,
      currentPhoto: null,
      currentItemId: null,
      currentItemIndex: null,
      currentPicacode: '',
      loadingPhotosForReplacement: false,
      loadingPhotoReplacement: false,
      loadingAlbumForReplacement: false,
      loadingAlbumReplacement: false,
      loadingSetOrderAsPaid: false,
      placeholderLandscape: require('@/assets/icons/placeholder-landscape.svg'),
      placeholderPortrait: require('@/assets/icons/placeholder-portrait.svg'),
      picaServiceIdForAlbumReplacement: null,
      timeZonePickerValue: null,
      timezone: null,
    }
  },

  computed: {
    orderStatus() {
      return orderStatus
    },
    ...mapGetters('event', {eventTimeZone: 'timeZone'}),
    isInEventOrders() {
      return this.$store.getters["event/isInEvent"] && this.$store.getters["event/picaServiceId"]
    },
    showTimeZonePicker() {
      return this.hasPerm('dashboard.view_timezone_select')
    },
    customTimezoneList() {
      const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
      const eventTimezone = this.eventTimeZone || "Europe/Rome"
      const utcTimezone = "UTC"
      return [...new Set([eventTimezone, browserTimezone, utcTimezone])].sort()
    },
    fields() {
      let fields = this.orderItemsFields
      if (!this.hasPerm('orders.fields.pica_code'))
        fields = fields.filter(x => x.key !== 'picacode')
      if (!this.hasPerm('orders.fields.net_photo_service'))
        fields = fields.filter(x => x.key !== 'price')
      if (!this.hasPerm('orders.fields.amount_tax'))
        fields = fields.filter(x => x.key !== 'amount_tax')
      if (!this.hasAnyPerms(['pica_ecommerce.change_order', 'orders.media.list']))
        fields = fields.filter(x => x.key !== 'actions')
      if (!this.hasPerm('orders.fields.during_stay') || !this.hasOrderItemsWithoutStayDates)
        fields = fields.filter(x => x.key !== 'end_of_stay' && x.key !== 'start_of_stay')

      return fields
    },
    hasOrderItemsWithoutStayDates() {
      if (!this.order || !this.order.order_items) return false
      return this.order.order_items.some(item => item.start_of_stay || item.end_of_stay)
    },
  },


  async mounted() {
    await this.fetchConfig({type: 'order'})
    this.timeZonePickerValue = this.eventTimeZone || "Europe/Rome"
    const orderId = this.$route.params['orderId']
    OrderService.retrieveOrder(orderId)
        .then(response => {
          this.order = response.data

          // this.order.order_items.forEach(oi => {
          //     MediaService.getMedias({"order_item": oi.id})
          //         .then(response => {
          //             this.$set(this.orderItemsPhotos, oi.id, response.data.results)
          //         })
          // })

        })

    this.$root.$on('select-photo-for-replacement', (id) => {
      this.selectPhotoForReplacement(id)
    })
  },
  methods: {
    ...mapActions('login', ['fetchConfig']),
    onDatePickerInput(value) {
      this.timezone = value.timezone
    },
    loadOrderItemPhotos(orderItemId) {
      const orderItem = this.order.order_items.find(item => item.id === orderItemId)
      MediaService.getOrderItemMedia(orderItem.id)
          .then(response => {
            this.$set(this.orderItemsPhotos, orderItem.id, response.data.results)
          })
    },
    toggleDetails(item) {

      if (!item._toggled) {
        this.loadOrderItemPhotos(item.id)
      }

      this.$set(item, "_toggled", !item._toggled)

      this.collapseDuration = 300
      this.$nextTick(() => {
        this.collapseDuration = 0
      })

    },

    loadModalReplacePhoto(order_item_id, photo, order_item_photos, picacode, pica_service_id) {
      this.currentItemId = order_item_id
      this.currentPhoto = photo
      this.selectedPhotoId = photo.id
      this.selectablePhotosForReplacement = []
      this.showModalReplacePhoto = true

      this.loadingPhotosForReplacement = true

      MediaService.getMedias({
        pica_service: pica_service_id,
        picacode: picacode,
        media_type: "photo",
        page_size: 1000
      })
          .then(response => {
            let selectable_photos = response.data.results.filter(function (objFromA) {
              return !order_item_photos.find(function (objFromB) {
                return objFromA.id === objFromB.id
              })
            })
            this.$set(this, 'selectablePhotosForReplacement', selectable_photos)
            this.loadingPhotosForReplacement = false
          })
    },

    loadModalReplaceAlbum(order_item_id, order_item_index, picacode, pica_service_id) {
      this.currentItemId = order_item_id
      this.currentItemIndex = order_item_index
      this.currentPicacode = picacode
      this.picaServiceIdForAlbumReplacement = pica_service_id
      this.$refs.OrderPhotoGalleryAlbumReplacement.reset()
      this.showModalReplaceAlbum = true
    },

    selectPhotoForReplacement(id) {
      this.selectedPhotoId = id
    },

    replacePhoto(currentPhoto, selectedPhotoId) {
      this.loadingPhotoReplacement = true

      OrderService.replaceMedia(this.order.id, this.currentItemId, {
        "old_media_id": currentPhoto.id,
        "new_media_id": selectedPhotoId
      })
          .then(async () => {
            await this.loadOrderItemPhotos(this.currentItemId)
            this.loadingPhotoReplacement = false
            this.showModalReplacePhoto = false
          })
    },

    setOrderAsPaid() {
      this.loadingSetOrderAsPaid = true

      OrderService.setStatus(this.order.id, {
        "status": orderStatus.PAID
      })
          .then(response => {
            this.$set(this, 'order', response.data)
            this.loadingSetOrderAsPaid = false
          })
    },

    replaceAlbum() {
      this.loadingAlbumReplacement = true

      OrderService.replaceAlbum(this.order.id, this.currentItemId, {
        "picacode": this.$refs.OrderPhotoGalleryAlbumReplacement.currentPicacode,
      })
          .then(async () => {
            await this.loadOrderItemPhotos(this.currentItemId)
            this.$set(this.order.order_items[this.currentItemIndex], 'picacode', this.$refs.OrderPhotoGalleryAlbumReplacement.currentPicacode)
            this.loadingAlbumReplacement = false
            this.showModalReplaceAlbum = false
          })
    },
    getStatusColor(value) {
      return {
        'created': 'text-secondary',
        'pending': 'text-warning',
        'paid': 'text-success',
        'gifted_pending': 'text-secondary',
        'gifted': 'text-secondary',
        'shipped': 'text-secondary',
        'refunded': 'text-secondary',
      }[value]
    },

  }
}
</script>
