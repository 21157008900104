<template>
  <div class="photo-card w-100">

      <figure class="figure w-100">
        <v-lazy-image :src="photo.image_url" class="figure-img img-fluid rounded order__figure photo-card__image w-100"
                      :src-placeholder="photo.original_width > photo.original_height ? placeholderLandscape : placeholderPortrait"
                      :class="{ 'selected-item': selected && editable }"/>
        <figcaption class="figure-caption">ID: {{ photo.id }}</figcaption>
      </figure>
      <a v-if="editable" href="#" @click.stop.prevent="select" class="photo-card__select-toggle"></a>
    </div>
</template>

<script>

export default {
    name: "OrderPhotoItem",

    components: {
        // PButton
    },

    props: {
        photo: {
            type: Object,
        },

        selected: {
            type: Boolean,
            default: false
        },

        editable: {
            type: Boolean,
            default: false
        },

        mode: {
            type: String,
            default: 'replace'
        }
    },

    data() {
        return {
            loading: false,
            placeholderLandscape: require('@/assets/icons/placeholder-landscape.svg'),
            placeholderPortrait: require('@/assets/icons/placeholder-portrait.svg'),
        }
    },

    methods: {
        select() {
            let event_to_emit = this.mode === 'replace' ? 'select-photo-for-replacement' : 'select-photo-to-add'

            if (!this.selected || event_to_emit !== 'replace') {
                this.$root.$emit(event_to_emit, this.photo.id)
            }
        },
    }
}
</script>

<style scoped lang="scss">
.photo-card {
  position: relative;

  &__image {
    object-fit: contain;
  }
}

.photo-card__select-toggle {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

</style>
