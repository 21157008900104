<template>
    <div>
        <CRow>
            <CCol xs="auto" sm="4" md="3" xl="3" class="d-flex align-items-stretch">
                <div style="min-width: 18rem;">
                    <div class="input-group">
                        <input class="form-control py-2 border-right-0 border" type="search" value="search" id="example-search-input"
                               placeholder="Enter the code" v-model="searchInput" @search="loadPhotos">
                        <span class="input-group-append">
                        <div class="input-group-text bg-transparent">
                            <CIcon name="cil-find-in-page"/>
                        </div>
                    </span>
                    </div>
                </div>
            </CCol>
        </CRow>
        <CRow v-if="picacodePhotos.length > 0 || loading">
            <CCol xs="12" class="px-3 pt-4 pb-2">
                <h6><translate>Photos</translate></h6>
            </CCol>
        </CRow>
        <CRow v-if="picacodePhotos.length > 0 || loadingPhotos">
            <CCol v-if="loadingPhotos" sm="12" class="text-center">
                <CSpinner grow />
                <h2 v-translate>Loading photos...</h2> <!-- caricamento foto... -->
            </CCol>
            <CCol v-else xs="auto" sm="4" md="3" xl="3" v-for="(photo, index) in picacodePhotos" :key="index" class="d-flex align-items-stretch">
                <OrderPhotoItem :photo="photo" :editable="false"/>
            </CCol>
        </CRow>
        <CRow v-else-if="currentPicacode">
            <CCol sm="12" class="text-center">
                <p class="pt-5 h5">
                    <translate>No results found for</translate> "{{ currentPicacode }}"
                </p>
            </CCol>
        </CRow>
    </div>
    <!--    <img src={b.logo} class="rounded float-left" alt="..." />-->
</template>

<script>
import OrderPhotoItem from "@/components/OrderPhotoItem";
import MediaService from "@/services/media.service";

export default {
    name: "OrderPhotoGalleryPhotoReplacement",

    components: {
        // PButton
        OrderPhotoItem
    },

    props: {
        picaServiceId: {
            type: Number,
        },

        oldPicacode: {
            type: String
        },

        loading: {
            type: Boolean
        }
    },

    data() {
        return {
            loadingPhotos: false,
            searchInput: '',
            currentPicacode: '',
            picacodePhotos: []
        }
    },

    methods: {
        loadPhotos() {
            this.loadingPhotos = true
            this.currentPicacode = this.searchInput

            if (this.oldPicacode !== this.currentPicacode) {
                MediaService.getMedias({
                    pica_service: this.picaServiceId,
                    picacode: this.currentPicacode,
                    media_type: "photo",
                    page_size: 1000
                })
                    .then(response => {
                        this.$set(this, 'picacodePhotos', response.data.results)
                        this.loadingPhotos = false
                    })
            } else {
                this.currentPicacode = ''
                this.picacodePhotos = []
                this.loadingPhotos = false
            }

        },
        reset() {
            this.searchInput = ''
            this.currentPicacode = ''
            this.picacodePhotos = []
        }
    }
}
</script>
