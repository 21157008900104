import client_core from "@/services/clients/client_core";

const MediaService = {
    getMedias(params) {
        return client_core.get('dashboard/v1/medias/', { params })
    },
    getOrderItemMedia(orderitem) {
        return client_core.get(`dashboard/v2/order/item/${orderitem}/media/`)
    },
    removeMediaPicacode(mediaId, picacode) {
        return client_core.post(`dashboard/v1/medias/${mediaId}/remove-picacode/`, { picacode: picacode })
    },
    addMediaToOrder(mediaId, picacode) {
        return client_core.post(`dashboard/v1/medias/${mediaId}/add-to-external-order/`, { picacode: picacode })
    },
    removeMediaFromOrder(mediaId, picacode) {
        return client_core.post(`dashboard/v1/medias/${mediaId}/remove-from-order/`, { picacode: picacode })
    },
    downloadHdZip(payload) {
        return client_core.post(`dashboard/v1/medias/download-hd-zip/`, payload, { responseType: 'blob' })
    },

}

export default MediaService
