<template>
    <div>
        <CRow>
            <CCol xs="12" class="px-3 pt-3 pb-2">
                <h6><translate>Current photo</translate></h6>
            </CCol>
        </CRow>
        <CRow>
            <CCol xs="auto" sm="4" md="3" xl="3" class="d-flex align-items-stretch">
                <OrderPhotoItem :photo="currentPhoto" :selected="currentPhoto.id === selectedPhotoId" :editable="true"/>
            </CCol>
        </CRow>
        <CRow v-if="selectablePhotos.length > 0 || loading">
            <CCol xs="12" class="px-3 pt-2 pb-2">
                <h6><translate>Other photos</translate></h6>
            </CCol>
        </CRow>
        <CRow v-if="selectablePhotos.length > 0 || loading">
            <CCol v-if="loading" sm="12" class="text-center">
                <CSpinner grow />
                <h2 v-translate>Loading photos...</h2>
            </CCol>
            <CCol v-else xs="auto" sm="4" md="3" xl="3" v-for="(photo, index) in selectablePhotos" :key="index" class="d-flex align-items-stretch">
                <OrderPhotoItem :photo="photo" :selectedPhotoId="selectedPhotoId" :editable="true"/>
            </CCol>
        </CRow>
    </div>
    <!--    <img src={b.logo} class="rounded float-left" alt="..." />-->
</template>

<script>
import OrderPhotoItem from "@/components/OrderPhotoItem";

export default {
    name: "OrderPhotoGalleryPhotoReplacement",

    components: {
        // PButton
        OrderPhotoItem
    },

    props: {
        picaServiceId: {
            type: Number,
        },

        selectablePhotos: {
            type: Array
        },

        selectedPhotoId: {
            type: Number
        },

        currentPhoto: {
            type: Object
        },

        loading: {
            type: Boolean
        }
    },
}
</script>
